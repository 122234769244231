/* Astrobots.css */
@import '../../styles/colors.css';
@import '../../static/fonts/fonts.css';

.footer-container {
  background-color: rgb(0, 0, 0);
  padding: 6px;
  text-align: center;
  width: 100%;
  font-family: 'Raleway-Bold';
  display: flex;
  justify-content: center;
  gap: 150px;
  border: 2px solid var(--gold);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer-container-button {
  color: var(--blue-bell);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  /*  letter-spacing: 1px;*/
  font-family: 'Raleway-Regular';
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  .footer-container {
    gap: 25px;
  }
}
