/*SubscriptionPage.css*/

@import "../../styles/colors.css";
@import "../../static/fonts/fonts.css";
@import "../../styles/designConstants.css";

/* Containers */
.subscriptionpage-container {
  display: flex; /* Создает flex контейнер */
  flex-direction: column; /* Ставит дочерние элементы в вертикальное направление */
  justify-content: space-between; /* Распределяет пространство между элементами */
  height: 100%; /* Задает высоту контейнера */
}

.subscriptionpage-container-2 {
  flex: 1;
  justify-content: center;
  width: 90%;
  margin: auto;
}

.subscriptionpage-inner-container {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: clamp(10px, 1.5vh, 30px);
  padding-bottom: clamp(10px, 3vh, 30px);
  height: auto;
/*  margin-top: clamp(5px, 1vh, 20px);
  margin-bottom: clamp(5px, 1vh, 20px);*/
  justify-content: center;
  border-radius: 25px;
  border-color: var(--text-color);
  border-width: var(--border-width);
  background-color: rgba(149, 147, 251, 0.25);
  border: 1px solid var(--akaroa)
}

.subscriptionpage-rowContainer {
  display: flex;
  justify-content: center;
  gap: clamp(20px, 8vw, 100px);
  margin-top: clamp(5px, 1vh, 30px);
  margin-bottom: clamp(5px, 1vh, 20px);
}

.subscriptionpage-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.subscriptionpage-featuresContainer {
  margin-top: 0px;
  margin-bottom: 20px;
}

/* Text */
.subscriptionpage-title {
  font-size: clamp(10px, 4vh, 40px);
  text-align: center;
  padding-top: 4px;
  font-family: "Raleway-Regular";
  color: var(--text-color);
  margin-top: 0px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  /*  background-image: linear-gradient(to bottom, rgb(223 190 131) 0%, #aa824b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px -2px 7px rgba(255, 255, 255, 0.3), -2px 2px 5px rgba(0, 0, 0, 0.7);
  filter: brightness(150%) contrast(150%) saturate(150%);*/
}

.subscriptionpage-pricing {
  color: var(--blue-bell);
  margin: 0 10px;
}

.subscriptionpage-pricing1 {
  color: var(--blue-bell);
  text-align: center;
  margin-top: clamp(10px, 3vh, 30px);
  margin-bottom: clamp(10px, 2vh, 20px);
}



.subscriptionpage-font20 {
  font-size: clamp(8px, min(2vh, 3vw), 25px);
  font-family: "Raleway-SemiBold";
}

.subscriptionpage-description {
  margin-top: clamp(8px, min(1vh, 2vw), 16px);
  white-space: normal;
  text-align: justify;
  font-size: clamp(8px, min(1.5vh, 3vw), 20px);
  line-height: clamp(10px, min(2vh, 4vw), 24px);
  margin-bottom: clamp(8px, min(1.5vh, 3vw), 20px);
}


/* Submit */
.subscriptionpage-submitFrame {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /*height: 100px;*/
}

.subscriptionpage-submitButton {
  width: 49%; /* Занимает почти половину контейнера */
}

.singleButton {
  width: 100%; /* Занимает весь контейнер, если кнопка одна */
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  .subscriptionpage-pricing1 {
    margin-top: 0px;
    margin-bottom: -5px;
  }

  .subscriptionpage-featuresContainer {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .subscriptionpage-rowContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .subscriptionpage-title {
    font-size: clamp(10px, 4vh, 40px);
    margin-bottom: 0px;
  }

  .subscriptionpage-description {
    margin-top: clamp(8px, min(1vh, 2vw), 16px);
    white-space: normal;
    text-align: justify;
    font-size: clamp(8px, min(1.5vh, 3vw), 20px);
    line-height: clamp(10px, min(2vh, 4vw), 24px);
    margin-bottom: 0px;
  }
}
