/* SocialLoginButton.css */
.logoIconLayout {
  height: 36px;
  width: 36px;
}

.continueSpaceBlock {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
/*  margin: 0 15px;*/
}
