/* Subscription.css */
@import "../../styles/colors.css";
@import "../../static/fonts/fonts.css";
@import "../../styles/designConstants.css";


.subscription-paginationDots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
}

.subscription-dot {
  width: 10px;
  height: 10px;
  margin: 20px;
  border-radius: 50%;
  bottom: 20px;
}

.subscription-activeDot {
  background-color: var(--text-color);
}

.subscription-inactiveDot {
  background-color: var(--text-color);
  opacity: 0.25;
  pointer-events: none;
}

/* Containers */
.subscription-upper-container {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--overlay-max-height) - 10px);
}

.subscription-container {
  display: flex;
  scroll-snap-type: x mandatory;
}

.subscription-container-page {
  flex: 0 0 100vw;
  width: 100vw;
  scroll-snap-align: start;
  /*margin-top: var(--header-height);*/
}

.subscription-margin-top {
  margin-top: var(--header-height);
}

.subscription-error-message {
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  .subscription-dot {
    margin: 13px;
  }
}

