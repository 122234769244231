@import '../../styles/colors.css';
@import '../../styles/designConstants.css';

.container .multiline-input-text-input {
  height: calc(var(--input-height, 48px) * 3);
  padding: 5px;
  overflow-y: auto; /*Добавлено для возможности прокрутки */
  overflow-x: hidden;
  outline: none;
  resize: none;
}
