@import '../../styles/designConstants.css';

.greeting-form-content-flex1 {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height) - var(--footer-height) - 10px);
}

.greeting-form-content-container {
  flex: 1;
  overflow: auto;
}

.greeting-form-list-itemImage {
  height: auto;
  margin-bottom: 10px;
  float: right; /* Располагаем картинку справа */
  width: 40%; /* Задаём ширину картинки */
  min-width: min(400px, 85vw);
  max-width: 600px;
  margin-left: 20px; /* Добавляем отступ слева для текста */
  border-radius: 25px; /* Закругляем углы картинки */
  /*border: 1px solid red;*/
}

  .greeting-form-list-itemImage.odd {
    float: left;
    margin-right: 20px;
    margin-left: 0;
  }

.greeting-form-list-titleText {
  padding-left: calc(var(--border-radius)*2);
  padding-right: calc(var(--border-radius)*2);
  font-size: clamp(16px, 3vw, 26px);
  line-height: clamp(20px, 4vw, 32px);
  display: inline-block;
  text-align: center;
}

.greeting-form-list-itemContainer {
  margin: 0 20px;
}

.greeting-form-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 15px;

}

.greeting-form-submit-button-container {
  width: 49%;

}

.greeting-form-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(191, 171, 147, 0.25);
  border-radius: 25px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid var(--akaroa);
  justify-content: center;
}

.greeting-form-list-titleContainer {

}