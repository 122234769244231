/* inputStyles.css */
@import 'designConstants.css';
@import 'colors.css';
@import '../static/fonts/fonts.css';

#root {
}

.container {
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding-top: 5px;
}

.top-aligned-div {
  display: flex;
  justify-content: space-around;
  position: absolute; /* Абсолютное позиционирование для дочернего элемента */
  top: 0; /* Прикрепляем к верхнему краю контейнера */
  left: 50%; /* Прикрепляем к левому краю контейнера, если требуется */
  transform: translateX(-50%);
  gap: calc(100vw - 700px);
}

.width100 {
  width: 100%;
}

.size100 {
  width: 100%;
  height: 100%;
  padding: 0 var(--padding-horizontal);
}

.width50 {
  width: 49%;
  height: auto;
}

.text {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  font-family: 'Raleway-Regular';
  color: var(--text-color);
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}

.titleText {
  font-size: 23px;
  line-height: 32px;
  color: var(--text-color);
  /*text-align: left;*/
  text-align: center;
  font-family: 'Raleway-Regular';
  margin-top: 0px;
  margin-bottom: 0px;
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  .titleText {
    font-size: 14px;
    line-height: 20px;
  }
}

.dateTextBox {
  display: flex;
  justify-content: center;
}

.border {
  border-radius: var(--border-radius);
  border-color: var(--border-blue);
  border-width: var(--border-width);
  padding: 0 var(--padding-horizontal);
  height: var(--input-height);
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 2px;
  border: 1px solid #000000;
}

.focused {
  border-color: var(--gold);
  border-style: solid;
}

.errorText {
  font-size: var(--font-size-medium);
  font-family: 'Raleway-SemiBold';
  letter-spacing: 1px;
  color: #FF1111;
  text-align: left;
  margin-top: 5px;
}

.scaledLogo {
  padding-top: 25px;
  margin-bottom: -20px;
  /*transform: scale(0.7);*/ /* CSS does not support the 'transform' property value from an external variable directly */
}

.bottom10 {
  padding-bottom: 10px;
}

.left20 {
  margin-left: 20px;
}

.overlay {
  margin: auto;
  width: 80%;
  height: fit-content;
  background-color: rgba(149, 147, 251, 0.25);
  min-width: 300px;
  border-radius: 25px;
  max-width: 800px;
  padding: 20px;
  overflow: auto;
  border: 1px solid var(--akaroa);
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  .overlay {
    max-height: calc(100vh - var(--header-height) - var(--footer-height) - 100px);
    padding-top: 5px;
    padding-bottom: 10px;
  }
}

.bottom-div {
  /*margin-top: clamp(18px, 15vw, 112px); */
}

.parent-centered-div {
  display: block;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
}

.centered-div {
  /* Стили для центрируемого div, например, ширина и высота */
  /*  width: 50%;  
  height: 50%; */
}

/* Paginator */
.paginationDots {
  display: flex;
  flex-direction: row;
  justify-content: center;
/*  position: absolute;
  left: 0;
  right: 0;*/
}

.dot {
  width: 10px;
  height: 10px;
  margin: 20px;
  border-radius: 50%;
  bottom: 20px;
}

.activeDot {
  background-color: var(--text-color);
}

.inactiveDot {
  background-color: var(--text-color);
  opacity: 0.25;
  pointer-events: none;
}

/* Arrow */
.arrow {
  cursor: pointer;
  font-size: clamp(30px, 15vh, 150px);
  color: var(--text-color);
  font-family: 'Distant-Feeling';
  transform: scaleY(2);
}

  .arrow.transparent {
    opacity: 0.25;
    pointer-events: none;
  }

.left-arrow {
  left: 0px;
}

.right-arrow {
  right: 0;
  position: absolute;
}

.loading-spinner {
  width: 10vh;
  height: 10vh;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
/*  background: rgba(255, 255, 255, 0.8);*/
  z-index: 1000;
}

.spinning-logo {
  width: 10vh;
  height: 10vh;
/*  height: 10vh;*/
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

