.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px clamp(10px, 2vw, 20px); /* Добавляем отступы по бокам */
  box-sizing: border-box; /* Учитываем отступы в ширине элемента */
}

  .header > .main-page-astrologai-text {
    flex-grow: 1;
    text-align: center; /* Выравниваем текст посередине */
  }

  .header > .main-page-language-selector {
    flex-shrink: 0; /* Не даем элементу сжиматься */
    margin-left: auto; /* Сдвигаем элемент к правому краю */
  }

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*padding: 0 20px;*/ /* Добавляем отступы по бокам */
  box-sizing: border-box; /* Учитываем отступы в ширине элемента */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-logo {
  width: clamp(16px, 10vw, 70px);
  height: clamp(16px, 10vw, 70px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: none;
  /*cursor: pointer;*/
  background-color: transparent;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 170px - 44px);
  box-sizing: border-box;
  /*padding-bottom: 24px;*/
  overflow-y: auto;
  height: calc(100vh - 170px - 44px);
}


@media (max-width: 768px) {

  .main-content {
    min-height: calc(100vh - 100px - 44px);
  }
}
