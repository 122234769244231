:root {
  --border-width: 1.2px;
  --border-radius: 10px;
  --input-height: 40px;
  --padding-horizontal: 12px;
  --top-offset-40: 40px;
  --font-size-small: 12px;
  --font-size-medium: 16px;
  --font-size-large: 20px;
  --padding-small: 8px;
  --padding-medium: 12px;
  --padding-large: 16px;
  --astrologyai-text-line-height: clamp(20px, 15vw, 150px);
  --header-height: calc(var(--astrologyai-text-line-height) + 10px);
  --footer-height: 43px;
  --button-border-width: 2px; /* Новая переменная для ширины рамки */
  --icon-width: calc(min(15vw, (100vh - var(--astrologyai-text-line-height) - 400px) / 3));
  --icon-width-1200: calc(min(24vw, 14vh));
  --overlay-max-height: 80vh;
}

/* Медиа-запрос для устройств с высокой плотностью пикселей (например, мобильные устройства) */
@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi) {
  :root {
    --input-height: 40px; /* Значение для мобильных устройств с высокой плотностью пикселей */
    --overlay-max-height: calc(100vh - var(--header-height) - var(--footer-height) - 60px);
    --icon-width: calc(min(20vw, (100vh - var(--astrologyai-text-line-height) - 400px) / 3));
    --footer-height: 60px;
  }
}
