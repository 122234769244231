/* ContentSelector.css */
.spinner-border {
  width: 3rem;
  height: 3rem;
}

.error-text {
  color: #ff0000; /* Пример цвета ошибки, можно изменить */
}

.contact-settings-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contact-settings-text-container {
  margin-top: 10px;
  text-align: justify;
}

