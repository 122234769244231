@import "../../styles/colors.css";

.radio-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Добавлено для растяжения контейнера */
}

  .radio-container label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%; /* Добавлено для растяжения контейнера */
  }

    .radio-container label:last-child {
      margin-bottom: 0;
    }

  .radio-container input[type="radio"]:checked + .text {
    color: var(--blue-bell);
  }

  .radio-container input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--blue-bell);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle; /* Выравнивание по середине */
    position: relative; /* Добавлено для смещения */
    top: -2px; /* Сдвиг вверх на 5 пикселей */
  }

    .radio-container input[type="radio"]::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      margin: 3px;
      border-radius: 50%;
      background: var(--blue-bell);
      opacity: 0;
      transition: opacity 0.2s;
    }

    .radio-container input[type="radio"]:checked::before {
      opacity: 1;
    }

.radio-selector {
  width: 16px; /* Увеличить размер селектора */
  height: 16px; /* Увеличить размер селектора */
  margin-right: 10px; /* Для отступа между селектором и текстом */
  vertical-align: middle; /* Выравнивание по середине */
}

.radio-label {
  margin-left: 10px;
 
}


