.filtered-picker-list-container {
  height: auto;
  overflow-y: auto; /* Если вам нужно прокрутить список */
  max-height: 120px;
  border-radius: 5px;
  padding-left: 10px;
  background: var(--backdrop-color-light);

}

.filtered-picker-list-item {
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  font-family: 'Raleway-Regular';

}

.filtered-picker-text-input {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
}

.static-picker-text-input {
  display: flex;
  align-items: center;
  height: 100%;
}

.filtered-picker-text-input-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}