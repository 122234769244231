.menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

}

.menu-button {
  border-radius: 25px;
  background-color: var(--dark-blue);
  border-right: 2px solid var(--border-color-right);
  border-top: 2px solid var(--border-color-top);
  border-bottom: 2px solid var(--border-color-bottom);
  border-left: 2px solid var(--border-color-left);
  width: 100%;
  height: var(--input-height);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  width: 80%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

  .menu-button:active {
    border-right-color: var(--border-color-left);
    border-top-color: var(--border-color-bottom);
    border-bottom-color: var(--border-color-top);
    border-left-color: var(--border-color-right);
  }

  .menu-button:hover {
    filter: brightness(120%); /* Пример, замените на значение из colors */
  }

.menu-text {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--light-text);
  text-align: center;
  font-size: 16px;
  font-family: 'Raleway-SemiBold';
}
