/* Terms.css */
.terms-title {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Raleway-Medium";
}

.size100 {
  width: 100%;
  height: 100%;
}

.terms-container {
/*  background-color: rgba(149, 147, 251, 0.0);*/
}
