.submit-buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tranzila-submit-button {
  border-radius: 25px; /* Пример, замените на значение из designConstants */
  background-color: var(--dark-blue); /* Пример, замените на значение из colors */
  border-right: 2px solid var(--border-color-right);
  border-top: 2px solid var(--border-color-top);
  border-bottom: 2px solid var(--border-color-bottom);
  border-left: 2px solid var(--border-color-left);
  width: 100%;
  height: var(--input-height); /* Пример, замените на значение из designConstants */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
  .tranzila-submit-button:active {
    border-right-color: var(--border-color-left);
    border-top-color: var(--border-color-bottom);
    border-bottom-color: var(--border-color-top);
    border-left-color: var(--border-color-right);
  }

  .tranzila-submit-button:hover {
    filter: brightness(120%);
  }

.tranzila-submit-text {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-color); /* Пример, замените на значение из colors */
  text-align: center;
  font-size: 16px;
  font-family: "Raleway-SemiBold"; /*, - убедитесь, что шрифт доступен в вашем веб-проекте */
}