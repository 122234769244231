.timeinput-date-text-box {
  display: flex;
  justify-content: center; 
}

.timeinput-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Mono', monospace;
  font-feature-settings: 'tnum';
}

.timeinput-time-picker {
  width: 100%;
}

.react-datepicker-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
  margin: auto;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  justify-content: center;
  display: flex;
}

.dateinput-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Mono', monospace;
  font-feature-settings: 'tnum';
}
