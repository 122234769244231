/* Verification.css */
.verification-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.verification-title {
  margin-top: 0px;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.verification-enterCode {
  font-size: 20px;
  text-align: center;
}

.verification-container {
/*  height: 450px;
  min-height: 450px;*/
  padding-left: 20px;
  padding-right: 20px;
}