/* ContentSelector.css */
.spinner-border {
  width: 3rem;
  height: 3rem;
}

.error-text {
  color: #ff0000; /* Пример цвета ошибки, можно изменить */
}

.content-selector-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content-selector-centered-div {
  padding: 20px;
}

