/* SignUp.css */
.signup-titleText {
  text-align: center;
  margin-block-start: clamp(5px, 1.5vh, 20px);
  margin-block-end: 0px;
}

.signup-item-container {
  width: 80%;
  margin: auto;
  padding-top: clamp(5px, 1vh, 20px);
  padding-bottom: clamp(5px, 1vh, 20px);
  max-width: 800px;
}



.signup-form-container {
  overflow: hidden;
  height: fit-content;
}

.signup-overlay-height {
  padding-bottom: 20px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi) {
  .signup-form-container {
/*    height: 540px;*/
  }

  .signup-item-container {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .signip-overlay-height {
    min-height: 760px;
  }
}