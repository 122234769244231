/*VerificationForm.css*/

@import "../../styles/colors.css";
@import "../../static/fonts/fonts.css";
@import "../../styles/designConstants.css";

.verificationform-rectangle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.verificationform-ressend {
  color: var(--blue-bell);
  text-align: center;
  background-color: transparent;
  border: none;
  font-family: 'Raleway-Bold';

}

.verificationform-title {
  display: flex;
  justify-content: center;
  align-items: center;
}