@import '../../styles/colors.css';
@import '../../styles/designConstants.css'; 

.checkboxLabelLink-container {
  display: flex;
  align-items: center;
  padding-top: 12px;
}

.checkboxLabelLink-checkbox {
  width: calc(var(--input-height) - 20px); /* Пример значения, задайте свое */
  display: flex;
  justify-content: center;
  align-items: center;
/*  border: 1px solid var(--text-color);*/
  margin-right: 20px;
  border-radius: 10px;
}

.checkboxLabelLink-icon {
  font-size: 40px;
}

.checkboxLabelLink-checked {
  color: var(--blue-bell); /* Пример значения, задайте свое */
}

.checkboxLabelLink-prompt {
  padding-left: 10px;
}

.checkboxLabelLink-wrapper {
  display: flex;
  align-items: center;
}

div.checkboxLabelLink-checkbox {
  border-radius: 10px;
}
