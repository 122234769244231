/* src/fonts/fonts.css */
@font-face {
  font-family: 'Raleway-Black';
  src: url('./Raleway-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('./Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Regular';
  src: url('./Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('./Raleway-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Moglan';
  src: url('./Moglan_DEMO.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Futura-Extra-Black';
  src: url('./Futura Extra Black font.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Vibes';
  src: url('./ClassicVibesDemoRegular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Distant-Feeling';
  src: url('./Distant Feeling.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Klandestin';
  src: url('./Klandestin Demo.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Casthelic';
  src: url('./Casthelic Demo.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Vogue';
  src: url('./ClassyVoguePersonalUse.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}






@font-face {
  font-family: 'Crucial-Medium';
  src: url('./Crucial-Medium.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Gracheva';
  src: url('./Gracheva.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ramongs-Regular';
  src: url('./Ramongs-Regular-SVG.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rofane';
  src: url('./Rofane.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Megira';
  src: url('./Megira Font by Keithzo.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Usetoit';
  src: url('./UsetoitPersonalUse-Regular.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family: 'Rheaski';
  src: url('./Rheaski demo.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


