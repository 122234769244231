@import '../../styles/colors.css';
@import '../../static/fonts/fonts.css';
@import '../../styles/designConstants.css';

.astrologyai-container {
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

:root {
  --letter-height: 2px;
}

.astrologyai-text {
  white-space: nowrap;
  position: relative;
  margin: 0 auto;
  font-family: 'Vogue';
  text-align: center;
  letter-spacing: clamp(0.5px, 0.2vw, 2.5px);
  line-height: var(--astrologyai-text-line-height);
  font-size: clamp(16px, 11vw, 80px);
  z-index: 1;
  /*  color: var(--gold);*/
  background-image: linear-gradient(to bottom, rgb(223 190 131) 0%, #aa824b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px -2px 7px rgba(255, 255, 255, 0.3), -2px 2px 5px rgba(0, 0, 0, 0.7);
  filter: brightness(150%) contrast(150%) saturate(150%);
}

.astrologyai-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-family: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
  z-index: 2;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  background-image: none;
}

.astrologyai-text-shadow {
  position: absolute;
  /*  top: 50%;*/
  left: 50%;
  transform: translate(calc(-49%), 0%);
  color: var(--gold);
  z-index: -1;
}
