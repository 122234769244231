@import '../../styles/colors.css';
@import '../../styles/designConstants.css';
@import '../../static/fonts/fonts.css';

.genderPicker-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
/*  width: 400px;*/

}

.genderPicker-radioButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--input-height, 48px) - 2px); /* Use a CSS variable or static value */
  cursor: pointer;
  border-color: var(--secondary-text);
  border-width: 1px;
  border-radius: calc(var(--border-radius, 4px) - 2px);
  background-color: transparent;
}

.genderPicker-selectedButton {
  background-color: #4d5682;
  color: var(--text-color);
  border-color: var(--text-color);
  border-width: 1px;
  border-radius: calc(var(--border-radius, 4px) - 2px);

}

.genderPicker-radioTextSelected {
  color: var(--text-color);
  font-family: 'Raleway-Bold';
}

.genderPicker-inputText {
/*  padding-left: 10px;
  height: calc(var(--input-height, 48px) - 2px);
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  font-family: 'Raleway-Regular';*/
  outline: none;
}

.genderPicker-larger-button {
  width: 33%; /* Ширина кнопок, когда не выбран 'other' */
}

.genderPicker-smaller-button {
  width: 20%; /* Ширина кнопок, когда выбран 'other' */
}

.genderPicker-wide-input {
  width: 40%; /* Ширина инпута, когда выбран 'other' */
}




