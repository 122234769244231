.custom-form-container {
/*  width: 95%;*/
  margin: auto;
/*  border: 1px solid red;*/
  box-sizing: border-box;
  min-width: 200px;

}

/*@media (max-width: 390px) {
  .custom-form-container {
    width: 100%;
    max-width: 100%;
    min-width: 0;
  }
}*/
