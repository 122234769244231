@import '../../styles/colors.css';

.input-label {
  text-align: left;
  margin-left: 0px;
}

.custom-input-container {
  align-items: flex-start;
  min-width: 0;
/*  border: 1px solid #ccc;*/
}

.custom-input-field {
  width: 100%;
  box-sizing: border-box;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: var(--text-color) !important; /* Применяем цвет текста из .text */
}

  .custom-input-field:-webkit-autofill,
  .custom-input-field:-webkit-autofill:hover,
  .custom-input-field:-webkit-autofill:focus,
  .custom-input-field:-webkit-autofill:active,
  .custom-input-field:autofill { /* Новый псевдокласс, если поддерживается */
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    background-color: var(--input-background) !important;
    -webkit-text-fill-color: var(--text-color) !important;
  }

.custom-input-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}



.custom-input-toggle-password {
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  /*font-family: 'Arial Unicode MS', sans-serif;*/
  color: var(--blue-bell)
}


@media (max-width: 390px) {
  .custom-input-container {
    display: flex; /* Если ещё не установлено, делаем контейнер flex-контейнером */
    flex-direction: column; /* Элементы будут расположены вертикально */
    flex: 1 1 auto; /* Контейнер может расти и сжиматься */
    width: 100%; /* Устанавливаем ширину контейнера в 100% */
    max-width: 100%; /* Максимальная ширина контейнера также 100%, чтобы не выходить за пределы */
    box-sizing: border-box; /* Включаем padding и border в общую ширину */
  }

  .custom-input-field {
    flex: 0 1 auto; /* Не растягивать, но позволить сжиматься */
    margin: 0; /* Убедитесь, что внешние отступы отсутствуют или корректны */
    padding: 10px; /* Установите желаемый padding или уменьшите его */
    box-sizing: border-box; /* Включить padding и border в общую ширину */
    width: 100%; /* Ширина в процентах от родительского элемента */
    max-width: 100%; /* Чтобы элемент не выходил за пределы контейнера */
  }
}