/* SocialLogin.css */
.sociallogin-continueWithSocialsParent {
  margin-top: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sociallogin-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 10px;
}

.sociallogin-continueWithAppleLeftAli1 {
  margin-left: 32px;
}

.sociallogin-loader {
  /* Add your loading spinner CSS here */
  color: #0000ff; /* Placeholder for actual loading spinner styling */
}

.sociallogin-sign-text {
  padding-top: clamp(5px, 1vh, 20px);
  padding-bottom: clamp(5px, 1vh, 20px);
  text-align: center;
}
