/*FeatureBox.css*/

@import "../../styles/colors.css";
@import "../../static/fonts/fonts.css";
@import "../../styles/designConstants.css";

.featurebox-container {
  display: flex;
  align-items: center;
  padding-top: clamp(5px,calc(min(1.5vh, 2vw)), 16px);
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  .featurebox-container {
    display: flex;
    align-items: center;
    padding-top: 0px;
  }
}

.featurebox-box {
  width: clamp(0px, calc(min(3vh, 4vw) - 2*var(--border-radius)), 60px);
  height: clamp(20px, min(3vh, 4vw), 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--text-color);
}

.featurebox-checked {
  /* backgroundColor: 'opasity'; */
}

.featurebox-tick {
  color: var(--blue-bell); /* Use the actual color variable for darkSeaGreen */
  font-weight: bold;
  font-size: clamp(10px, 3vh, 30px);
}

.featurebox-label {
  margin-left: 16px;
  font-size: clamp(8px, min(1.5vh, 3vw), 20px);
}