.language-selector-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-selector-button {
  width: clamp(16px, 10vw, 70px);
  height: clamp(16px, 10vw, 70px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
