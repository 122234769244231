/* VerificationSquare.css */

@import "../../styles/colors.css";
@import "../../static/fonts/fonts.css";
@import "../../styles/designConstants.css";

.verificationsquare-rectangleview {
  background-color: var(--text-color);
  height: var(--input-height);
  width: var(--input-height);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 10px;
}

.verificationsquare-textInput {
  font-size: 30px;
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
}
