/* ScrollingText.css */
@import '../../styles/colors.css';
@import '../../static/fonts/fonts.css';
@import '../../styles/designConstants.css';

.scrollingtext-text {
  letter-spacing: 0;
  color: #000;
  text-align: justify;
  font-family: 'Raleway-SemiBold';
}

.scrollingtext-background {
  background-color: var(--backdrop-color-light);
  padding: 20px;
  border-radius: var(--border-radius);
  overflow-y: auto;
  height: calc(100vh - var(--header-height) - 165px - var(--footer-height));
}

.scrollingtext-boldText {
  font-family: 'Raleway-Bold';
  font-size: 20px;
}
