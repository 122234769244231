@import '../../styles/colors.css';
@import '../../static/fonts/fonts.css';
@import '../../styles/designConstants.css';
@import '../../styles/inputStyles.css';

.calendar-button-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  background-color: transparent;
  margin-top: var(--calendar-button-container-margin-top);
  /*    border: 1,
    borderColor: "red",*/
}

.prediction-bottom-button-container {
  position: absolute;
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  z-index: 999;
}

:root {
  --calendar-button-item-height: 60px;
  --calendar-button-container-margin-top: 0px;
  --calendar-button-item-color: rgb(156 131 82);
}

.calendar-button-item {
  padding: 10px;
  width: 33.3%;
  height: var(--calendar-button-item-height);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /*  background-color: var(--gold);*/
  background-color: var(--calendar-button-item-color);
  border-radius: var(--border-radius);
  border-right: 3px solid var(--border-color-right);
  border-top: 3px solid var(--border-color-top);
  border-bottom: 3px solid var(--border-color-bottom);
  border-left: 3px solid var(--border-color-left);
  transition: border-color 0.1s ease-out;
}
  .calendar-button-item:hover {
    filter: brightness(120%);
  }

  .calendar-button-item.selected {
    border-right-color: var(--border-color-left);
    border-top-color: var(--border-color-bottom);
    border-bottom-color: var(--border-color-top);
    border-left-color: var(--border-color-right);
  }

.calendar-button-label {
  font-family: 'Raleway-SemiBold';
  font-size: 18px;
  margin-left: 15px;
  opacity: 0.5;
}

  .calendar-button-label.selected {
    opacity: 1.0;
  }

.calendar-button-icon {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi) {
  .calendar-button-icon {
    width: 30px;
    height: 30px;
  }

  .calendar-button-label {
    font-family: 'Raleway-SemiBold';
    font-size: 12px;
    margin-left: 4px;
    opacity: 0.5;
  }

  :root {
    --calendar-button-item-height: 40px;
  }
}


  .calendar-button-icon.selected {
    opacity: 1.0;
  }

.date-prediction-container {
  flex: 1;
  margin-top: 100px;
  width: 100%;
  /* Optional border for debugging */
  /* border: 1px solid red; */
}

.prediction-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.prediction-overlay {
/*  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;*/
/*  margin: auto;*/
  height: fit-content;
  z-index: 9999;
  min-width: 300px;
/*  margin-top: var(--header-height);*/
  /*height: calc(var(--overlay-max-height) - 10px);*/
}

.prediction-scaledLogo {
  /* You might want to translate specific styles from your inputStyles */
}

.prediction-content-flex1 {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height) - 2*var(--calendar-button-item-height) - var(--calendar-button-container-margin-top) - var(--footer-height));
}

.prediction-content-container {
  flex: 1;
  overflow: auto;
}

.prediction-list-container {
  display: flex;
  flex-direction: column;
  background-color: var(--transparent); /* Замените на фактическое значение цвета */
  width: 100%;
  height: 100%;
}

.prediction-list-itemContainer {
  margin: 0 20px;

}

.prediction-list-titleText {
  padding-left: 20px;
}

.prediction-list-itemImage {
  height: auto;
  margin-bottom: 10px;
  float: right ; /* Располагаем картинку справа */
  width: 40%; /* Задаём ширину картинки */
  max-width: 500px;
  margin-left: 20px; /* Добавляем отступ слева для текста */
  border-radius: 25px; /* Закругляем углы картинки */

}



.prediction-list-descriptionText {
  text-align: justify !important;
  padding-top: 5px;
  padding-bottom: 15px;
  overflow: hidden;
  font-size: clamp(12px, 1.5vh, 16px);
  line-height: clamp(12px, 2vh, 24px);

}



.prediction-list-astrobotImage {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid var(--blue-bell);
}

.prediction-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(191, 171, 147, 0.25);
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid var(--akaroa);
}

.prediction-list-titleContainer {
  flex: 1;
  display: flex;
  align-items: center;
}


::-webkit-scrollbar {
  width: 16px; /*ширина полосы прокрутки */
}

/* Убираем базовый стиль для всех кнопок */
::-webkit-scrollbar-button {
  display: none; /* По умолчанию скрываем все кнопки */
}

  /* Стилизуем только кнопку "вверх" */
  ::-webkit-scrollbar-button:start:decrement {
    display: block;
    background-color: var(--gold);
    height: 16px;
    width: 16px;
    border-right: 8px solid var(--border-color-right);
    border-top: 8px solid var(--border-color-top);
    border-bottom: 8px solid var(--border-color-bottom);
    border-left: 8px solid var(--border-color-left);
  }

    ::-webkit-scrollbar-button:start:decrement:hover {
      border-right: 4px solid var(--border-color-right);
      border-top: 4px solid var(--border-color-top);
      border-bottom: 12px solid var(--border-color-bottom);
      border-left: 4px solid var(--border-color-left);
    }

  /* Стилизуем только кнопку "вниз" */
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    background-color: var(--gold);
    height: 16px;
    width: 16px;
    border-right: 8px solid var(--border-color-right);
    border-top: 8px solid var(--border-color-top);
    border-bottom: 8px solid var(--border-color-bottom);
    border-left: 8px solid var(--border-color-left);
  }

    ::-webkit-scrollbar-button:end:increment:hover {
      border-right: 4px solid var(--border-color-right);
      border-top: 12px solid var(--border-color-top);
      border-bottom: 4px solid var(--border-color-bottom);
      border-left: 4px solid var(--border-color-left);
    }

::-webkit-scrollbar-track {
  background: var(--gold); /*цвет дорожки прокрутки */
  margin-bottom: 0;
}

::-webkit-scrollbar-thumb {
  background: var(--calendar-button-item-color); /*цвет полосы прокрутки */
  border-right: 2px solid var(--border-color-right);
  border-top: 2px solid var(--border-color-top);
  border-bottom: 2px solid var(--border-color-bottom);
  border-left: 2px solid var(--border-color-left);
  border-radius: 5px;
}

  ::-webkit-scrollbar-thumb:hover {
    background: var(--border-color-bottom); /*цвет полосы прокрутки при наведении */
  }


::-webkit-scrollbar-corner {
  display: none;
}


::-webkit-resizer {
  display: none;
}
