

.zodiac-container {
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  /*height: calc(100vh - clamp(20px, 15vw, 120px) - 60px);*/ /* Займет всю видимую высоту экрана */
  height: calc(100vh - var(--header-height) - var(--calendar-button-item-height) - var(--calendar-button-container-margin-top) - var(--footer-height));
  overflow-y: auto;
  /*  margin-top: 10px;*/
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: hidden;
}


.zodiac-table {
  display: grid;
  grid-template-columns: var(--icon-width) var(--icon-width) var(--icon-width) var(--icon-width);
  grid-column-gap: 2vw;
  justify-content: center;
  grid-row-gap: 2vh;
}

.zodiac-table-cell {
  display: flex;
  /*justify-content: center;*/
  width: var(--icon-width);
  max-width: 300px;
  box-sizing: border-box;
/*  border: 1px solid yellow;*/
}


@media (max-width: 1200px) {
  .zodiac-table {
    grid-template-columns: var(--icon-width-1200) var(--icon-width-1200) var(--icon-width-1200);
    grid-column-gap: 3vw;
    justify-content: center;
  }

  .zodiac-table-cell {
    width: var(--icon-width-1200);
    max-width: 200px;
    height: calc(var(--icon-width) + 0px);
    margin-top: 25px;
  }
}

@media (max-width: 768px) and (min-resolution: 2dppx) {
  /*  .zodiac-table {
    grid-column-gap: 2vw;
    grid-row-gap: 1vh;
  }*/
}
