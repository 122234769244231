/* CheckboxLabel.css */
.checkboxlabel-container {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
  flex-direction: row;
}

.checkboxlabel-checkbox {
  width: calc(var(--input-height) - 2 * var(--border-radius)); /* Пример значения, задайте свое */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--text-color);
}

.checkboxlabel-icon {
  font-size: 40px;
}

/* Add your color and visibility handling here */
.checkboxlabel-visible {
  /* define the visible state style, for example: */
  color: /* your blueBell color here */;
}

.checkboxlabel-border {
  /* define the border style here */
}

.checkboxlabel-text {
  /* define the text style here */
}

.checkboxlabel-left20 {
  margin-left: 20px;
}
