@import '../../styles/colors.css';
@import '../../static/fonts/fonts.css';
@import '../../styles/designConstants.css';
@import '../../styles/inputStyles.css';

.icon-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  flex-direction: column;
  background-color: transparent;
  border: none;
/*  border: 1px solid red;*/
}

.icon-button-padding {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
/*  border: 1px solid green;*/
}

.icon-button-imageStyle {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  /*  border: 1px solid var(--gold);*/
  cursor: pointer;
  position: relative;
  z-index: 1;
}


.icon-button-wrapper {
  width: var(--icon-width);
  position: relative;
  border-radius: var(--border-radius);
  border-right: var(--button-border-width) solid var(--border-color-right);
  border-top: var(--button-border-width) solid var(--border-color-top);
  border-bottom: var(--button-border-width) solid var(--border-color-bottom);
  border-left: var(--button-border-width) solid var(--border-color-left);
  /* transition: border-color 0.1s;*/
}

/*@media (max-width: 1200px) {
  .icon-button-wrapper {
    width: var(--icon-width-1200);
  }
}*/


.icon-button-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: calc(2*var(--border-radius));
  border: var(--button-border-width) solid transparent;
  border-right-color: var(--border-color-right);
  border-top-color: var(--border-color-top);
  border-bottom-color: var(--border-color-bottom);
  border-left-color: var(--border-color-left);
  z-index: 0;
}


.icon-button-container:active .icon-button-wrapper {
  border-right-color: var(--border-color-left);
  border-top-color: var(--border-color-bottom);
  border-bottom-color: var(--border-color-top);
  border-left-color: var(--border-color-right);
}

.icon-button-container:active .icon-button-imageStyle {
  filter: brightness(85%); /* Уменьшение яркости изображения при нажатии */
}

.icon-button-container:hover .icon-button-imageStyle {
  filter: brightness(120%); /* Увеличение яркости изображения при наведении */
}

.icon-button-text {
  font-size: clamp(10px, 1.5vh, 26px);
  line-height: 30px;
  margin-top: 5px;
  font-family: Raleway-Bold;
  position: relative;
  display: inline-block;
  color: var(--gold);
  background-image: linear-gradient( var(--gold) 100%, var(--gold) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0.5px -0.5px 1px rgba(255, 255, 255, 0.4), -0.5px 0.5px 1px rgba(0, 0, 0, 0.7);
  filter: brightness(110%) contrast(150%) saturate(150%);
}
