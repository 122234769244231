.confirmation-container {
  display: flex;
}

.confirmation-left-column, .confirmation-right-column {
  flex: 1;
  padding: 20px;
}

  .confirmation-left-column h2 {
/*    text-align: left;*/
    font-size: 24px;
    margin-bottom: 10px;
  }

  .confirmation-left-column p, .confirmation-left-column ul {
    font-size: 16px;
    margin-bottom: 10px;
  }

.confirmation-price-container {
  margin-top: 20px;
  /*width: 40vw;*/
}

.confirmation-price-row {
  display: flex;
  justify-content: space-between;
  font-family: monospace;
  font-size: 16px;
}

.confirmation-right-container {
  padding-top: 45px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi) {
  .confirmation-price-row {
    font-size: 14px;
  }

  .confirmation-left-column {
    width: 32vw;
    padding: 10px;
  }
  .confirmation-right-column {
    width: 32vw;
    padding: 10px;
  }

  .confirmation-right-container {
    padding-top: 15px;
  }
}

.confirmation-price-value {
  text-align: right;
  width: 6em; /* Adjust width as needed */
}



.google-pay-icon {
  height: 20px; /* Настройте размер по вашему усмотрению */
  margin-right: 8px; /* Добавьте отступ, чтобы текст не прилипал к иконке */
  vertical-align: middle; /* Выравнивание иконки по середине */
  padding-bottom: 3px;
}



.promo-code {
  margin-bottom: 20px;
}

  .promo-code label {
    font-size: 16px;
  }

  .promo-code input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
/*    margin-bottom: 10px;*/
    border: 1px solid #ccc;
    border-radius: 5px;
  }

.apply-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.payment-buttons {
  display: flex;
  justify-content: space-between;
}

.pay-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin-right: 10px;
}

  .pay-button:last-child {
    margin-right: 0;
  }

.cancel-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}


.success-container {
  text-align: center;
  padding: 20px;
}

.success-icon {
  margin-bottom: 20px;
}

.success-message {
  margin-bottom: 15px;
}

.success-icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #4CAF50;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.success-icon-check {
  width: 30px;
  height: 50px;
  border: solid #fff;
  border-width: 0 6px 6px 0;
  transform: rotate(45deg);
  margin-bottom: 10px;
}
