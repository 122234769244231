:root {
  --text-color: #fffba7;
  --text-color1: #e3cb9a;
  --light-text: #e3cb9a;
  --secondary-text: #777777;
  --highlighted-text: #FF5733;
  --border-color: #fafafa;
  --focused-border-color: blue;
  --placeholder-text-color: #797A5E;
  --backdrop-color-light: #c5c4fd;
  --backdrop-color-dark: rgb(198, 198, 156);
  --gold: rgb(216,181,112);
  --dark-blue: #20202A;
  --border-blue: #414153;
  --border-blue-focused: #8282A6;
  --bronze: #B87333;
  --pale-blue: #7FDBFF;
  --dark-purple: #4B0082;
  --blue-bell: #9593FB;
  --gold-bell: #FAFB93;
  --green-mist: #BDBF93;
  --akaroa: #BFAB93;
  --norway: #A7BF93;
  --rosy-brown: #FB9393;
  --dark-sea-green: #93FB94;
  --transparent: rgba(0, 0, 0, 0.5);
 
  --border-color-top: #f0e5b4; /* Новая переменная для золотого цвета рамки */
  --border-color-right: #ad8f61; /* Новая переменная для золотого цвета рамки */
  --border-color-bottom: #817151; /* Новая переменная для серого цвета рамки */
  --border-color-left: #bf914a; /* Новая переменная для серого цвета рамки */
}
