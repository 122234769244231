/* PromptWithActionLink.css */
@import "../../styles/colors.css";
@import "../../static/fonts/fonts.css";

.promptWithActionLink-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  /*padding: 10px 10px;*/ /* Добавлено для отступов внутри контейнера */
/*  padding-left: 20px;*/
}

.promptWithActionLink-link {
  color: var(--blue-bell);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;

  font-family: 'Raleway-Regular';
  text-align: left;
  /*  font-family: Raleway-SemiBold;
  font-size: clamp(8px, min(3.5vw, 1.75vh), 16px);*/
  /*margin-left: auto;*/ /* Изменено на margin-left */
}

.promptWithActionLink-text {
  /*margin-right: auto;*/ /* Изменено на margin-right */
/*  font-family: Raleway-SemiBold;
  font-size: clamp(8px, min(3.5vw, 1.75vh), 16px);*/
  color: var(--text-color);
}
