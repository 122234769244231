@import '../../styles/colors.css';

.top50 {
  flex: 1;
  margin-top: 100px;
}

.signin-titleText {
  text-align: center;
}

.signin-centered-div {
  width: 80%;
  margin: auto;
}


@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi) {
  .signin-centered-div {
    width: 100%;
    margin: auto;
  }
}


.signin-form-container {
  width: 90%;
  margin: auto;
}

.signin-item-container {
  width: 80%;
  margin: auto;
  padding-top: clamp(5px, 1vh, 20px);
  padding-bottom: clamp(5px, 1vh, 20px);
  max-width: 800px;
}

.signin-message {
  color: var(--blue-bell) !important;
  font-family: Raleway-SemiBold !important;
  font-size: clamp(8px, min(3.5vw, 1.75vh), 16px) !important;
}


  /* .scaled {
    padding-top: 25px;
    margin-bottom: -20px;
    transform: scale(0.5);
} */
